







import CharacterDisplay from './smart/CharacterDisplay.vue';

export default {
  components: {
    CharacterDisplay,
  },
};
