










































































































































import { CharacterPower, CharacterTrait, GetTotalMultiplierForTrait, IWeapon, WeaponTrait } from '@/interfaces';
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { toBN, fromWeiEther } from '../../utils/common';

interface PriceJson {
  binancecoin: CoinPrice;
  cryptoblades: CoinPrice;
}

interface CoinPrice {
  usd: number;
}

export default Vue.extend({
  computed: {
    ...mapGetters([
      'currentCharacter',
      'currentWeapon',
      'fightGasOffset',
      'fightBaseline'
    ]),

    isLoadingCharacter(): boolean {
      return !this.currentCharacter;
    },
  },

  data() {
    return {
      characterElementValue: '',
      levelSliderValue: 1,
      starsValue: 1,
      wepElementValue: '',
      wepFirstStatElementValue: '',
      wepSecondStatElementValue: '',
      wepThirdStatElementValue: '',
      wepFirstStatSliderValue: 4,
      wepSecondStatSliderValue: 4,
      wepThirdStatSliderValue: 4,
      wepBonusPowerSliderValue: 0,
      bnbPrice: 0,
      skillPrice: 0,
      calculationResults: [] as number[][],
    };
  },

  methods: {
    async onShowEarningsCalculator() {
      if(this.currentCharacter !== null) {
        this.characterElementValue = CharacterTrait[this.currentCharacter.trait];
        this.levelSliderValue = this.currentCharacter.level + 1;
      }

      if(this.currentWeapon !== null) {
        this.starsValue = this.currentWeapon.stars + 1;
        this.wepElementValue = this.currentWeapon.element;
        this.wepFirstStatSliderValue = this.currentWeapon.stat1Value;
        this.wepSecondStatSliderValue = this.starsValue > 3 && this.currentWeapon.stat2Value;
        this.wepThirdStatSliderValue = this.starsValue > 4 && this.currentWeapon.stat3Value;
        this.wepFirstStatElementValue = this.currentWeapon.stat1;
        this.wepSecondStatElementValue = this.starsValue > 3 && this.currentWeapon.stat2;
        this.wepThirdStatElementValue = this.starsValue > 4 && this.currentWeapon.stat3;
        this.wepBonusPowerSliderValue = this.currentWeapon.bonusPower;
      }

      await this.fetchPrices();
      (this.$refs['earnings-calc-modal'] as any).show();
    },

    onReset() {
      this.characterElementValue = '';
      this.levelSliderValue =  1;
      this.starsValue =  1;
      this.wepElementValue =  '';
      this.wepFirstStatElementValue =  '';
      this.wepSecondStatElementValue =  '';
      this.wepThirdStatElementValue =  '';
      this.wepFirstStatSliderValue =  4;
      this.wepSecondStatSliderValue =  4;
      this.wepThirdStatSliderValue =  4;
      this.wepBonusPowerSliderValue =  0;
      this.calculationResults = [] as number[][];
    },

    getMinRoll(stars: number): number {
      switch(stars) {
      case 2: return 180;
      case 3: return 280;
      case 4: return 200;
      case 5: return 268;
      default: return 4;
      }
    },

    getMaxRoll(stars: number): number {
      switch(stars) {
      case 1: return 200;
      case 2: return 300;
      default: return 400;
      }
    },

    async fetchPrices() {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=cryptoblades,binancecoin&vs_currencies=usd');
      const data = response.data as PriceJson;
      this.bnbPrice = data?.binancecoin.usd;
      this.skillPrice = data?.cryptoblades.usd;
    },

    canCalculate(): boolean {
      if(!this.characterElementValue || !this.wepElementValue) return false;
      if(this.starsValue < 4 && !this.wepFirstStatElementValue) return false;
      if(this.starsValue === 4 && (!this.wepFirstStatElementValue || !this.wepSecondStatElementValue)) return false;
      if(this.starsValue === 5 && (!this.wepFirstStatElementValue || !this.wepSecondStatElementValue || !this.wepThirdStatElementValue)) return false;
      return true;
    },

    calculateEarnings() {
      if(!this.canCalculate()) return;
      this.calculationResults = [];
      const fightBnbFee = 0.0007 * this.bnbPrice;
      const weapon = this.getWeapon();
      const characterTrait = CharacterTrait[this.characterElementValue as keyof typeof CharacterTrait];
      const weaponMultiplier = GetTotalMultiplierForTrait(weapon, characterTrait);

      const totalPower = this.getTotalPower(CharacterPower(this.levelSliderValue - 1), weaponMultiplier, this.wepBonusPowerSliderValue);
      const averageReward = this.getAverageRewardForPower(totalPower);
      const averageFightProfit = averageReward * this.skillPrice - fightBnbFee;
      for(let i = 1; i < 8; i++) {
        const averageDailyProfitForCharacter = averageFightProfit * i - (7 - i) * fightBnbFee;
        const averageDailyProfitForAllCharacter = 4 * averageDailyProfitForCharacter;
        const averageMonthlyProfitForAllCharacter = 30 * averageDailyProfitForAllCharacter;
        this.calculationResults.push([averageDailyProfitForCharacter, averageDailyProfitForAllCharacter, averageMonthlyProfitForAllCharacter]);
      }
    },

    getWeapon(): IWeapon {
      const weapon = {
        stat1Type: WeaponTrait[this.wepFirstStatElementValue as keyof typeof WeaponTrait],
        stat2Type: WeaponTrait[this.wepSecondStatElementValue as keyof typeof WeaponTrait],
        stat3Type: WeaponTrait[this.wepThirdStatElementValue as keyof typeof WeaponTrait],
        stat1Value: this.wepFirstStatSliderValue,
        stat2Value: this.starsValue > 3 && this.wepSecondStatSliderValue || 0,
        stat3Value: this.starsValue > 4 && this.wepThirdStatSliderValue || 0,
      } as IWeapon;
      return weapon;
    },

    getTotalPower(characterPower: number, weaponMultiplier: number, bonusPower: number): number {
      return characterPower * weaponMultiplier + Number(bonusPower);
    },

    getAverageRewardForPower(power: number): number {
      return this.formattedSkill(this.fightGasOffset) + (this.formattedSkill(this.fightBaseline) * Math.sqrt(power / 1000));
    },

    getNextMilestoneBonus(level: number): string {
      const nextMilestoneLevel = this.getNextMilestoneLevel(level);
      return this.getRewardDiffBonus(level, nextMilestoneLevel);
    },

    getNextMilestoneLevel(level: number): number {
      return (Math.floor(level / 10) + 1) * 10 + 1;
    },

    getAverageRewardAtLevel(level: number): number {
      return this.formattedSkill(this.fightGasOffset) + (this.formattedSkill(this.fightBaseline) * (Math.sqrt(CharacterPower(level - 1)/1000)));
    },

    getRewardDiffBonus(level: number, targetLevel: number): string {
      return (this.getAverageRewardAtLevel(targetLevel) /
        this.getAverageRewardAtLevel(level + 1) * 100 - 100).toFixed(2);
    },

    formattedSkill(skill: number): number {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toNumber();
    },

    stringFormattedSkill(skill: number): string {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toFixed(6);
    },

    getColoringClass(i: number): string {
      if(!this.calculationResults.length || this.calculationResults[i][0] === 0) return '';
      if(this.calculationResults[i][0] < 0) return 'negative-value';
      return 'positive-value';
    },

    refreshWeaponStats(value: number) {
      this.wepFirstStatSliderValue = this.getMinRoll(value);
      this.wepSecondStatSliderValue = this.getMinRoll(value);
      this.wepThirdStatSliderValue = this.getMinRoll(value);
    }
  },
});
